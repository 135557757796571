import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

import {
  competitionRoutes,
  limitTimeActiviesRoutes,
  rankingOptionsRoutes,
  coachOptionsRoutes,
  recommanderPartnerRoutes,
  saleMachineRoutes,
  couponAndmemberAndDiamondRoutes,
  socialManageRoutes,
  venueSettingRoutes,
  websiteOperateManageRoutes,
  chatManageRoutes
} from './operationManageSub'

Vue.use(VueRouter);

/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 数据总览
export const dataWholeBoardRoutes = [
  {
    path: "/dataStatistic",
    name: "dataStatistic",
    meta: {
      title: "数据看板",
      key: 'data-total-board', // 同级唯一
      pageApisPrm: import("../views/dataWholeBoard/dataStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/dataStatistic.vue"
      ),
  },
  {
    path: "/dayDataStatistic",
    name: "dayDataStatistic",
    meta: {
      title: "每日数据统计",
      key: 'day-data-statistic', // 同级唯一
      pageApisPrm: import("../views/dataWholeBoard/dayDataStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/dayDataStatistic.vue"
      ),
  },
  {
    path: "/monthIncomeAudit",
    name: "monthIncomeAudit",
    meta: {
      title: "每月营收",
      key: 'month-income-summar', // 同级唯一
      pageApisPrm: import("../views/dataWholeBoard/monthIncomeAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/monthIncomeAudit.vue"
      ),
  },
  {
    path: "/merchantsAudit",
    name: "merchantsAudit",
    meta: {
      title: "台球营收详情",
      key: 'ball-income-summar',
      pageApisPrm: import("../views/dataWholeBoard/merchantsAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/merchantsAudit.vue"
      ),
  },
  {
    path: "/merchantsAuditByDay",
    name: "merchantsAuditByDay",
    meta: {
      title: "台球每日营收详情",
      key: 'ball-day-income-summar',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/dataWholeBoard/merchantsAuditByDay.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/merchantsAuditByDay.vue"
      ),
  },
  {
    path: "/merchantsGloryAudit",
    name: "merchantsGloryAudit",
    meta: {
      title: "棋牌营收详情",
      key: 'glory-income-summar',
      pageApisPrm: import("../views/dataWholeBoard/merchantsGloryAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/merchantsGloryAudit.vue"
      ),
  },
  {
    path: "/merchantsGloryAuditByDay",
    name: "merchantsGloryAuditByDay",
    meta: {
      title: "棋牌每日营收详情",
      key: 'glory-day-income-summar',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/dataWholeBoard/merchantsGloryAuditByDay.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/merchantsGloryAuditByDay.vue"
      ),
  },
  {
    path: "/shopIncomeChart",
    name: "shopIncomeChart",
    meta: {
      title: "店铺营收详情图表",
      key: 'shop-income-chart',
      pageApisPrm: import("../views/dataWholeBoard/shopIncomeChart.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/shopIncomeChart.vue"
      ),
  },
  {
    path: '/userManualConsumeRecord',
    name: 'userManualConsumeRecord',
    meta: {
      title: "用户台球消费记录（自助下单）",
      key: 'user-ball-consume-self-record',
      pageApisPrm: import("../views/dataWholeBoard/userManualConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/userManualConsumeRecord.vue"
      ),
  },
  {
    path: '/userCasherConsumeRecord',
    name: 'userCasherConsumeRecord',
    meta: {
      title: "用户台球消费记录（收银台）",
      key: 'user-ball-consume-cashier-record',
      pageApisPrm: import("../views/dataWholeBoard/userCasherConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/userCasherConsumeRecord.vue"
      ),
  },
  {
    path: '/coachPreviewStatistic',
    name: 'coachPreviewStatistic',
    meta: {
      title: "助教被浏览数据统计",
      key: 'coach-preview-statistic',
      pageApisPrm: import("../views/dataWholeBoard/coachPreviewStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/coachPreviewStatistic.vue"
      ),
  },
  {
    path: '/coachPreviewStatisticOneDay',
    name: 'coachPreviewStatisticOneDay',
    meta: {
      title: "(每日)助教被浏览数据统计",
      key: 'coach-preview-statistic-one-day',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/dataWholeBoard/coachPreviewStatisticOneDay.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/coachPreviewStatisticOneDay.vue"
      ),
  },
  // lvmiLampOptionLog
  {
    path: "/lvmiLampOptionLog",
    name: "lvmiLampOptionLog",
    meta: {
      title: "绿米灯控数据查询",
      key: 'lvmi-lamp-option-log', // 同级唯一
      pageApisPrm: import("../views/dataWholeBoard/lvmiLampOptionLog.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "../views/dataWholeBoard/lvmiLampOptionLog.vue"
      ),
  },
]

// 商家管理
export const merchantManageRoutes = [
  {
    path: "/merchantsList",
    name: "merchantsList",
    meta: {
      title: "商户列表",
      key: 'merchant-list',
      pageApisPrm: import("../views/merchantManage/merchantsList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "merchantManage" */ "../views/merchantManage/merchantsList.vue"
      ),
  },
  {
    path: "/molisMerchantList",
    name: "molisMerchantList",
    meta: {
      title: "麦力士店铺",
      key: 'molis-merchant-list',
      pageApisPrm: import("../views/merchantManage/molisMerchantList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "merchantManage" */ "../views/merchantManage/molisMerchantList.vue"
      ),
  },
  {
    path: "/rechargeRecord",
    name: "rechargeRecord",
    meta: {
      title: "店铺信息",
      key: 'venue-info-list',
      pageApisPrm: import("../views/merchantManage/rechargeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/rechargeRecord.vue"
      ),
  },
  {
    path: "/mutipleShopApplySameAuth",
    name: "mutipleShopApplySameAuth",
    meta: {
      title: "美团多店铺授权",
      key: 'mutiple-shop-apply-same-auth',
      pageApisPrm: import("../views/merchantManage/mutipleShopApplySameAuth.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/mutipleShopApplySameAuth.vue"
      ),
  },
  // mutipleAuthSubShopList
  {
    path: "/mutipleAuthSubShopList",
    name: "mutipleAuthSubShopList",
    meta: {
      title: "美团绑定授权子店铺列表",
      asideHidden: true, // 侧边栏不显示
      key: 'mutiple-auth-sub-shop-list',
      pageApisPrm: import("../views/merchantManage/mutipleAuthSubShopList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/mutipleAuthSubShopList.vue"
      ),
  },
  {
    path: "/withhold",
    name: "withhold",
    meta: {
      title: "球桌信息",
      asideHidden: true, // 侧边栏不显示
      key: 'ball-table-info-list',
      pageApisPrm: import("../views/merchantManage/withhold.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/withhold.vue"
      ),
  },
  // doorBlackList
  {
    path: "/doorBlackList",
    name: "doorBlackList",
    meta: {
      title: "门禁黑名单",
      asideHidden: true, // 侧边栏不显示
      key: 'door-black-list',
      pageApisPrm: import("../views/merchantManage/doorBlackList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/doorBlackList.vue"
      ),
  },
  // merchantOperationerList
  {
    path: "/merchantOperationerList",
    name: "merchantOperationerList",
    meta: {
      title: "绑定运营",
      asideHidden: true, // 侧边栏不显示
      key: 'merchant-operationer-list',
      pageApisPrm: import("../views/merchantManage/merchantOperationerList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/merchantOperationerList.vue"
      ),
  },
  {
    path: "/signboardLightManage",
    name: "signboardLightManage",
    meta: {
      title: "招牌灯",
      asideHidden: true, // 侧边栏不显示
      key: 'sign-board-light-list',
      pageApisPrm: import("../views/merchantManage/signboardLightManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/signboardLightManage.vue"
      ),
  },
  {
    path: "/withholdChess",
    name: "withholdChess",
    meta: {
      title: "包厢信息",
      asideHidden: true, // 侧边栏不显示
      key: 'cage-info-list',
      pageApisPrm: import("../views/merchantManage/withholdChess.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/withholdChess.vue"
      ),
  },
  {
    path: "/device",
    name: "device",
    meta: {
      title: "其他设备",
      asideHidden: true, // 侧边栏不显示
      key: 'other-device-info-list',
      pageApisPrm: import("../views/merchantManage/device.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/device.vue"
      ),
  },
  {
    path: "/air_config",
    name: "air_config",
    meta: {
      title: "空调",
      asideHidden: true, // 侧边栏不显示
      key: 'air-config-info-list',
      pageApisPrm: import("../views/merchantManage/airConfig.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/airConfig.vue"
      ),
  },
  {
    path: "/air_config_set",
    name: "air_config_set",
    meta: {
      title: "空调列表",
      asideHidden: true, // 侧边栏不显示
      key: 'air-config-set-info-list',
      pageApisPrm: import("../views/merchantManage/airConfigSet.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "../views/merchantManage/airConfigSet.vue"
      ),
  },
]


// 用户管理
export const userManageRoutes = [
  {
    path: "/userList",
    name: "userList",
    meta: {
      title: "用户列表",
      key: 'user-list',
      pageApisPrm: import("../views/userManage/userList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "userManage" */ "../views/userManage/userList.vue"),
  },
  {
    path: "/coachInfoManage",
    name: "coachInfoManage",
    meta: {
      title: "助教管理",
      key: 'coach-info-manage',
      pageApisPrm: import("../views/userManage/coachInfoManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "userManage" */ "../views/userManage/coachInfoManage.vue"),
  },
  {
    path: "/allianceBusinessList",
    name: "allianceBusinessList",
    meta: {
      title: "助教加盟商管理",
      key: 'coach-alliance-business-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/userManage/allianceBusinessList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "userManage" */ "../views/userManage/allianceBusinessList.vue"),
  },
  {
    path: "/orderList",
    name: "orderList",
    meta: {
      title: "台球订单",
      key: 'order-list',
      pageApisPrm: import("../views/userManage/orderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "../views/userManage/orderList.vue"
      ),
  },
  {
    path: "/chessOrderList",
    name: "chessOrderList",
    meta: {
      title: "棋牌订单列表",
      key: 'chess-order-list',
      pageApisPrm: import("../views/userManage/chessOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "../views/userManage/chessOrderList.vue"
      ),
  },
  {
    path: "/coachOrderList",
    name: "coachOrderList",
    meta: {
      title: "助教订单列表",
      key: 'coach-order-list',
      pageApisPrm: import("../views/userManage/coachOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "../views/userManage/coachOrderList.vue"
      ),
  },
  {
    path: '/goodsOrderList',
    name: 'goodsOrderList',
    meta: {
      title: "商品订单列表",
      key: 'goods-order-list',
      pageApisPrm: import("../views/userManage/goodsOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "../views/userManage/goodsOrderList.vue"
      ),
  }
]

// 赛事管理
export const competitionManageRoutes = [
  {
    path: '/settingCompetitionAssistant',
    name: 'settingCompetitionAssistant',
    meta: {
      title: "赛事助手",
      key: 'setting-competition-assistant',
      pageApisPrm: import("../views/competitionManage/settingCompetitionAssistant.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/settingCompetitionAssistant.vue"
      ),
  },
  // competitionCNNineBallBroadcastScreenOption
  {
    path: '/competitionCNNineBallBroadcastScreenOption',
    name: 'competitionCNNineBallBroadcastScreenOption',
    meta: {
      title: "中式九球大赛",
      key: 'competition-cn-nine-ball-broadcast-screen-option',
      pageApisPrm: import("../views/competitionManage/competitionCNNineBallBroadcastScreenOption.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionCNNineBallBroadcastScreenOption.vue"
      ),
  },
  {
    path: '/competitionBroadcastScreenOption',
    name: 'competitionBroadcastScreenOption',
    meta: {
      title: "九球大赛",
      key: 'competition-broadcast-screen-option',
      pageApisPrm: import("../views/competitionManage/competitionBroadcastScreenOption.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionBroadcastScreenOption.vue"
      ),
  },
  // competitionBroadcastScreenOption
  // competitionSnakeBroadcastScreenOption
  {
    path: '/competitionSnakeBroadcastScreenOption',
    name: 'competitionSnakeBroadcastScreenOption',
    meta: {
      title: "斯诺克直播",
      key: 'competition-snake-broadcast-screen-option',
      pageApisPrm: import("../views/competitionManage/competitionSnakeBroadcastScreenOption.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionSnakeBroadcastScreenOption.vue"
      ),
  },
  {
    path: '/studentSignupList',
    name: 'studentSignupList',
    meta: {
      title: "学生报名信息",
      key: 'student-signup-list',
      pageApisPrm: import("../views/competitionManage/studentSignupList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/studentSignupList.vue"
      ),
  },
  {
    path: '/allCompetitionPartnerList',
    name: 'allCompetitionPartnerList',
    meta: {
      title: "全部参赛选手",
      key: 'all-competition-partner-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/competitionManage/allCompetitionPartnerList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/allCompetitionPartnerList.vue"
      ),
  },

  // competitionPartnerPrePkSetList
  {
    path: '/competitionPartnerPrePkSetList',
    name: 'competitionPartnerPrePkSetList',
    meta: {
      title: "参赛选手列表",
      key: 'competition-partner-pre-pk-set-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/competitionManage/competitionPartnerPrePkSetList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionPartnerPrePkSetList.vue"
      ),
  },

  // competitionPkSetList
  {
    path: '/competitionPkSetList',
    name: 'competitionPkSetList',
    meta: {
      title: "比赛对局列表",
      showTitle: '',
      key: 'competition-pk-set-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/competitionManage/competitionPkSetList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionPkSetList.vue"
      ),
  },
  // competitionPointExcelRecord
  {
    path: '/competitionPointExcelRecord',
    name: 'competitionPointExcelRecord',
    meta: {
      title: "赛事积分导入",
      key: 'competition-point-excel-record',
      pageApisPrm: import("../views/competitionManage/competitionPointExcelRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionPointExcelRecord.vue"
      ),
  },
  {
    path: '/compeitionPointAdjustRecord',
    name: 'compeitionPointAdjustRecord',
    meta: {
      title: "赛事积分管理",
      key: 'compeition-point-adjust-record',
      pageApisPrm: import("../views/competitionManage/compeitionPointAdjustRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/compeitionPointAdjustRecord.vue"
      ),
  },
  // operationPartnerAmountAdjustRecord
  {
    path: '/operationPartnerAmountAdjustRecord',
    name: 'operationPartnerAmountAdjustRecord',
    meta: {
      title: "合伙人余额管理",
      key: 'operation-partner-amount-adjust-record',
      pageApisPrm: import("../views/competitionManage/operationPartnerAmountAdjustRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/operationPartnerAmountAdjustRecord.vue"
      ),
  },
  {
    path: '/addOrEditComplexCompetition',
    name: 'addOrEditComplexCompetition',
    meta: {
      title: "赛事详情",
      key: 'add-or-edit-complex-competition',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/competitionManage/addOrEditComplexCompetition.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/addOrEditComplexCompetition.vue"
      ),
  },
  {
    path: '/merchantApplyCompetition',
    name: 'merchantApplyCompetition',
    meta: {
      title: "赛事申请",
      key: 'merchant-apply-competition',
      pageApisPrm: import("../views/competitionManage/merchantApplyCompetition.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/merchantApplyCompetition.vue"
      ),
  },
  {
    path: '/merchantCreateCompetitionAudit',
    name: 'merchantCreateCompetitionAudit',
    meta: {
      title: "赛事创建审核",
      key: 'merchant-create-competition-audit',
      pageApisPrm: import("../views/competitionManage/merchantCreateCompetitionAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/merchantCreateCompetitionAudit.vue"
      ),
  },
  {
    path: '/merchantCreateCompetitionDetail',
    name: 'merchantCreateCompetitionDetail',
    meta: {
      title: "赛事创建详情",
      key: 'merchant-create-competition-detail',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/competitionManage/merchantCreateCompetitionDetail.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/merchantCreateCompetitionDetail.vue"
      ),
  },
  // competitionLiveSettingList
  {
    path: '/competitionLiveList',
    name: 'competitionLiveList',
    meta: {
      title: "赛事直播",
      key: 'competition-live-list',
      pageApisPrm: import("../views/competitionManage/competitionLiveList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionLiveList.vue"
      ),
  },
  {
    path: '/competitionLiveSettingList',
    name: 'competitionLiveSettingList',
    meta: {
      title: "直播设置",
      key: 'competition-live-setting-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/competitionManage/competitionLiveSettingList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "../views/competitionManage/competitionLiveSettingList.vue"
      ),
  },
]

// 熊猫客服
export const customerServiceRoutes = [
  {
    path: '/robotSettingList',
    name: 'robotSettingList',
    meta: {
      title: "机器人设置",
      key: 'robot-setting-list',
      pageApisPrm: import("../views/customerService/robotSettingList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "customerService" */ "../views/customerService/robotSettingList.vue"
      ),
  },
  // servicedRecord
  {
    path: '/servicedRecord',
    name: 'servicedRecord',
    meta: {
      title: "客服服务记录",
      key: 'serviced-record-list',
      pageApisPrm: import("../views/customerService/servicedRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "customerService" */ "../views/customerService/servicedRecord.vue"
      ),
  },
  {
    path: '/quickReplyManage',
    name: 'quickReplyManage',
    meta: {
      title: "快捷回复管理",
      key: 'quick-reply-manage',
      pageApisPrm: import("../views/customerService/quickReplyManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "customerService" */ "../views/customerService/quickReplyManage.vue"
      ),
  },
]

// 运营管理
export const operationManageRoutes = [
  ...competitionRoutes,
  {
    path: '/openOrderDurationList',
    name: 'openOrderDurationList',
    meta: {
      title: "开台时长榜",
      key: 'open-order-duration-list',
      pageApisPrm: import("../views/operationManage/openOrderDurationList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/openOrderDurationList.vue"
      ),
  },
  // guessingGamePraizesRecord 有奖竞猜记录
  {
    path: '/guessingGamePraizesRecord',
    name: 'guessingGamePraizesRecord',
    meta: {
      title: "有奖竞猜记录",
      key: 'guessing-game-praizes-record',
      pageApisPrm: import("../views/operationManage/guessingGamePraizesRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/guessingGamePraizesRecord.vue"
      ),
  },
  // 个人竞猜详情
  {
    path: '/guessingGamePersonalDetail',
    name: 'guessingGamePersonalDetail',
    meta: {
      title: "个人竞猜详情",
      key: 'guessing-game-personal-detail',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/operationManage/guessingGamePersonalDetail.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/guessingGamePersonalDetail.vue"
      ),
  },
  {
    path: '/guessingGamePraizesSetting',
    name: 'guessingGamePraizesSetting',
    meta: {
      title: "有奖竞猜设置",
      key: 'guessing-game-praizes-setting',
      pageApisPrm: import("../views/operationManage/guessingGamePraizesSetting.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/guessingGamePraizesSetting.vue"
      ),
  },

  {
    path: '/businessReminderList',
    name: 'businessReminderList',
    meta: {
      title: "运营提醒设置",
      key: 'business-reminder-list',
      pageApisPrm: import("../views/operationManage/businessReminderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/businessReminderList.vue"
      ),
  },
  // 大屏数据
  {
    path: '/boardcastBigViewList',
    name: 'boardcastBigViewList',
    meta: {
      title: "宣传大屏数据管理",
      key: 'boardcast-big-view-list',
      pageApisPrm: import("../views/operationManage/boardcastBigViewList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/boardcastBigViewList.vue"
      ),
  },
  // 礼物管理
  {
    path: '/coachGiftManage',
    name: 'coachGiftManage',
    meta: {
      title: "礼物管理",
      key: 'coach-gift-manage',
      pageApisPrm: import("../views/operationManage/coachGiftManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/coachGiftManage.vue"
      ),
  },
  // 话题管理
  {
    path: '/coachTopicManage',
    name: 'coachTopicManage',
    meta: {
      title: "话题管理",
      key: 'coach-topic-manage',
      pageApisPrm: import("../views/operationManage/coachTopicManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/coachTopicManage.vue"
      ),
  },

  // brilliantShowList
  {
    path: '/brilliantShowList',
    name: 'brilliantShowList',
    meta: {
      title: "精彩球列表",
      key: 'brilliant-show-list',
      pageApisPrm: import("../views/operationManage/brilliantShowList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/brilliantShowList.vue"
      ),
  },

  {
    path: '/userGradeAduitManage',
    name: 'userGradeAduitManage',
    meta: {
      title: "档位测试及认证",
      key: 'user-grade-aduit-manage',
      pageApisPrm: import("../views/operationManage/userGradeAduitManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "../views/operationManage/userGradeAduitManage.vue"
      ),
  },


  ...limitTimeActiviesRoutes,

  // openTimeLevelRewardRecord

  ...rankingOptionsRoutes,
  ...coachOptionsRoutes,
  ...recommanderPartnerRoutes,

  ...saleMachineRoutes,
  // saleMachineBlackUserList
  ...couponAndmemberAndDiamondRoutes,
  // recommanderManage
  // saleMachineBlackUserList

  ...venueSettingRoutes,
  ...socialManageRoutes,

  ...websiteOperateManageRoutes,
  ...chatManageRoutes

]

// 财务管理
export const financeManageRoutes = [
  {
    path: "/financeLeaseList",
    name: "financeLeaseList",
    meta: {
      title: "融资租赁",
      key: 'finance-lease-list',
      pageApisPrm: import("../views/financeManage/financeLeaseList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/financeLeaseList.vue"
      ),
  },
  // financeLeaseTakeRateList
  {
    path: "/financeLeaseTakeRateList",
    name: "financeLeaseTakeRateList",
    meta: {
      title: "抽成管理",
      key: 'finance-lease-take-rate-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/financeManage/financeLeaseTakeRateList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/financeLeaseTakeRateList.vue"
      ),
  },
  {
    path: "/abnormalPayOrderList",
    name: "abnormalPayOrderList",
    meta: {
      title: "异常订单查询",
      key: 'abnormal-pay-order-list',
      pageApisPrm: import("../views/financeManage/abnormalPayOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/abnormalPayOrderList.vue"
      ),
  },

  // accountMixCheckRecord
  {
    path: "/accountMixCheckRecord",
    name: "accountMixCheckRecord",
    meta: {
      title: "扣款记录",
      key: 'account-mix-check-record',
      pageApisPrm: import("../views/financeManage/accountMixCheckRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/accountMixCheckRecord.vue"
      ),
  },
  // {
  //   path: "/coachLeaderWithdrawAccountAudit",
  //   name: "coachLeaderWithdrawAccountAudit",
  //   meta: {
  //     title: "助教队长提现账户审核",
  //     key: 'coach-leader-withdraw-account-audit',
  //     pageApisPrm: import("../views/financeManage/coachLeaderWithdrawAccountAudit.vue").then(({ pageApiList }: any) => pageApiList)
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "financeManage" */ "../views/financeManage/coachLeaderWithdrawAccountAudit.vue"
  //     ),
  // },
  {
    path: "/coachLeaderWithdrawDepositAudit",
    name: "coachLeaderWithdrawDepositAudit",
    meta: {
      title: "助教队长提现审核",
      key: 'coach-leader-withdraw-deposit-audit',
      pageApisPrm: import("../views/financeManage/coachLeaderWithdrawDepositAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/coachLeaderWithdrawDepositAudit.vue"
      ),
  },

  {
    path: "/singleCardTransferRecord",
    name: "singleCardTransferRecord",
    meta: {
      title: "单店会员移植记录",
      key: 'single-card-transfer-record',
      pageApisPrm: import("../views/financeManage/singleCardTransferRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/singleCardTransferRecord.vue"
      ),
  },
  {
    path: "/singleCardConsumeRecord",
    name: "singleCardConsumeRecord",
    meta: {
      title: "美团单店卡核销记录",
      key: 'single-card-consume-record',
      pageApisPrm: import("../views/financeManage/singleCardConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/singleCardConsumeRecord.vue"
      ),
  },
  {
    path: "/technicalServiceFeeList",
    name: "technicalServiceFeeList",
    meta: {
      title: "技术服务费汇总",
      key: 'technical-service-fee-list',
      pageApisPrm: import("../views/financeManage/technicalServiceFeeList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/technicalServiceFeeList.vue"
      ),
  },
  {
    path: '/technicalServiceShopFeeList',
    name: "technicalServiceShopFeeList",
    meta: {
      title: "技术服务费店铺详情",
      key: 'technical-service-shop-fee-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/financeManage/technicalServiceShopFeeList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/technicalServiceShopFeeList.vue"
      ),
  },
  // technicalServiceShopIncomeList
  {
    path: '/technicalServiceShopIncomeList',
    name: "technicalServiceShopIncomeList",
    meta: {
      title: "技术服务费店铺收益明细",
      key: 'technical-service-shop-income-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("../views/financeManage/technicalServiceShopIncomeList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/technicalServiceShopIncomeList.vue"
      ),
  },
  {
    path: "/singleCardAccountCheckRecord",
    name: "singleCardAccountCheckRecord",
    meta: {
      title: "单店卡余额扣款",
      key: 'single-card-account-check-record-list',
      pageApisPrm: import("../views/financeManage/singleCardAccountCheckRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/singleCardAccountCheckRecord.vue"
      ),
  },
  {
    path: "/singleCardUseRecord",
    name: "singleCardUseRecord",
    meta: {
      title: "单店卡消费记录",
      key: 'single-card-use-record',
      pageApisPrm: import("../views/financeManage/singleCardUseRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/singleCardUseRecord.vue"
      ),
  },
  {
    path: "/cashierOrderList",
    name: "cashierOrderList",
    meta: {
      title: "收银台订单",
      key: 'cashier-order-list',
      pageApisPrm: import("../views/financeManage/cashierOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/cashierOrderList.vue"
      ),
  },
  // cashierGoodsOrderList
  {
    path: "/cashierGoodsOrderList",
    name: "cashierGoodsOrderList",
    meta: {
      title: "收银台商品订单",
      key: 'cashier-goods-order-list',
      pageApisPrm: import("../views/financeManage/cashierGoodsOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/cashierGoodsOrderList.vue"
      ),
  },
  {
    path: "/recommanderPartnerWithdrawAccountAudit",
    name: "recommanderPartnerWithdrawAccountAudit",
    meta: {
      title: "运营合伙人提现管理",
      key: 'recommander-partner-withdraw-account-audit-list',
      pageApisPrm: import("../views/financeManage/recommanderPartnerWithdrawAccountAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/recommanderPartnerWithdrawAccountAudit.vue"
      ),
  },
  {
    path: "/merchantCouponSaleRecord",
    name: "merchantCouponSaleRecord",
    meta: {
      title: "畅打券销售记录",
      key: 'merchant-coupon-sale-record',
      pageApisPrm: import("../views/financeManage/merchantCouponSaleRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/merchantCouponSaleRecord.vue"
      ),
  },
  {
    path: "/merchantWithdrawConsumeRecord",
    name: "merchantWithdrawConsumeRecord",
    meta: {
      title: "商家提现余额扣款记录",
      key: 'merchant-withdraw-remain-amount-consume-record',
      pageApisPrm: import("../views/financeManage/merchantWithdrawConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/merchantWithdrawConsumeRecord.vue"
      ),
  },
  {
    path: "/merchantWithdrawAddRecord",
    name: "merchantWithdrawAddRecord",
    meta: {
      title: "商家提现余额增加记录",
      key: 'merchant-withdraw-remain-amount-add-record',
      pageApisPrm: import("../views/financeManage/merchantWithdrawAddRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
            /* webpackChunkName: "financeManage" */ "../views/financeManage/merchantWithdrawAddRecord.vue"
      ),
  },
  {
    path: "/allMemberConsumeRecord",
    name: "allMemberConsumeRecord",
    meta: {
      title: "全国会员扣款记录",
      key: 'universal-member-consume-record',
      pageApisPrm: import("../views/financeManage/allMemberConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/allMemberConsumeRecord.vue"
      ),
  },
  {
    // withdrawAccountAudit
    path: "/withdrawAccountAudit",
    name: "withdrawAccountAudit",
    meta: {
      title: "商家端提现账户审核",
      key: 'merchant-withdraw-account-audit',
      pageApisPrm: import("../views/financeManage/withdrawAccountAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/withdrawAccountAudit.vue"
      ),
  },
  {
    path: "/coachWithdrawDeposit",
    name: "coachWithdrawDeposit",
    meta: {
      title: "助教提现管理",
      key: 'coach-withdraw-audit',
      pageApisPrm: import("../views/financeManage/coachWithdrawDeposit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/coachWithdrawDeposit.vue"
      ),
  },
  {
    path: "/withdrawDeposit",
    name: "withdrawDeposit",
    meta: {
      title: "提现管理",
      key: 'withdraw-deposit-manage',
      pageApisPrm: import("../views/financeManage/withdrawDeposit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "../views/financeManage/withdrawDeposit.vue"
      ),
  },
  {
    path: "/cueLockerIncome",
    name: "cueLockerIncome",
    meta: {
      title: "球杆柜收益",
      key: 'cue-locker-income',
      pageApisPrm: import("../views/financeManage/cueLockerIncome.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
            /* webpackChunkName: "financeManage" */ "../views/financeManage/cueLockerIncome.vue"
      ),
  },
  {
    path: "/matchEntryFeeMange",
    name: "matchEntryFeeMange",
    meta: {
      title: "比赛报名费管理",
      key: 'match-entry-fee-mange',
      pageApisPrm: import("../views/financeManage/matchEntryFeeMange.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
            /* webpackChunkName: "financeManage" */ "../views/financeManage/matchEntryFeeMange.vue"
      ),
  },
]

// 审核管理
export const auditManageRoutes = [
  // gloryOrderCageTypeAudit
  {
    path: "/gloryOrderCageTypeAudit",
    name: "gloryOrderCageTypeAudit",
    meta: {
      title: "棋牌分类审核",
      key: 'glory-order-cage-type-audit',
      pageApisPrm: import("../views/auditManage/gloryOrderCageTypeAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "../views/auditManage/gloryOrderCageTypeAudit.vue"),
  },
  {
    path: "/goodsRefundAudit",
    name: "goodsRefundAudit",
    meta: {
      title: "商品退款审核",
      key: 'goods-refund-audit',
      pageApisPrm: import("../views/auditManage/goodsRefundAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "../views/auditManage/goodsRefundAudit.vue"),
  },
  {
    path: "/coachActualPersonAudit",
    name: "coachActualPersonAudit",
    meta: {
      title: "助教真人审核",
      key: 'coach-actual-person-info-audit',
      pageApisPrm: import("../views/auditManage/coachActualPersonAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "../views/auditManage/coachActualPersonAudit.vue"),
  },
  {
    path: "/coachRegisterInfoAudit",
    name: "coachRegisterInfoAudit",
    meta: {
      title: "助教入驻审核",
      key: 'coach-register-info-audit',
      pageApisPrm: import("../views/auditManage/coachRegisterInfoAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "../views/auditManage/coachRegisterInfoAudit.vue"),
  },
  {
    path: "/coachBaseInfoAudit",
    name: "coachBaseInfoAudit",
    meta: {
      title: "助教基本资料审核",
      key: 'coach-base-info-audit',
      pageApisPrm: import("../views/auditManage/coachBaseInfoAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "../views/auditManage/coachBaseInfoAudit.vue"),
  },
  {
    path: "/coachUploadMediaAudit",
    name: "coachUploadMediaAudit",
    meta: {
      title: "照片视频审核",
      key: 'coach-upload-media-audit',
      pageApisPrm: import("../views/auditManage/coachUploadMediaAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "../views/auditManage/coachUploadMediaAudit.vue"),
  },
  {
    path: "/feedback",
    name: "feedback",
    meta: {
      title: "意见反馈",
      key: 'feedback-manage',
      pageApisPrm: import("../views/auditManage/feedback.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "../views/auditManage/feedback.vue"),
  },
]

// 官网管理
export const officialWebManageRoutes = [
  // marketDepartDataStatistic
  {
    path: "/shopInformationCollect",
    name: "shopInformationCollect",
    meta: {
      title: "店铺信息收集",
      key: 'shop-information-collect',
      pageApisPrm: import("../views/officialWebManage/shopInformationCollect.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "officialWebManage" */ "../views/officialWebManage/shopInformationCollect.vue"
      ),
  },
  // recruitPartnerContactList
  {
    path: "/recruitPartnerContactList",
    name: "recruitPartnerContactList",
    meta: {
      title: "招募合伙人联系信息",
      key: 'recruit-partner-contact-list',
      pageApisPrm: import("../views/officialWebManage/recruitPartnerContactList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "officialWebManage" */ "../views/officialWebManage/recruitPartnerContactList.vue"),
  },
  // websitePandaContactList
  {
    path: "/websitePandaContactList",
    name: "websitePandaContactList",
    meta: {
      title: "熊猫官网联系方式",
      key: 'panda-website-contact-list',
      pageApisPrm: import("../views/officialWebManage/websitePandaContactList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "officialWebManage" */ "../views/officialWebManage/websitePandaContactList.vue"),
  },
  // websiteMilexContactList
  {
    path: "/websiteMilexContactList",
    name: "websiteMilexContactList",
    meta: {
      title: "麦力士官网联系方式",
      key: 'milex-website-contact-list',
      pageApisPrm: import("../views/officialWebManage/websiteMilexContactList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "officialWebManage" */ "../views/officialWebManage/websiteMilexContactList.vue"),
  },
]


// 市场部
export const marketManageRoutes = [
  {
    path: "/marketDepartDataStatistic",
    name: "marketDepartDataStatistic",
    meta: {
      title: "市场部数据统计",
      key: 'market-depart-data-statistic',
      pageApisPrm: import("../views/marketManage/marketDepartDataStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "../views/marketManage/marketDepartDataStatistic.vue"
      ),
  },
  {
    path: "/clueSourceList",
    name: "clueSourceList",
    meta: {
      title: "线索来源",
      key: 'clue-source-list',
      pageApisPrm: import("../views/marketManage/clueSourceList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "../views/marketManage/clueSourceList.vue"
      ),
  },
  {
    path: '/molixLaunchList',
    name: 'molixLaunchList',
    meta: {
      title: "麦力士投放",
      key: 'molix-launch-list',
      pageApisPrm: import("../views/marketManage/molixLaunchList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "../views/marketManage/molixLaunchList.vue"
      ),
  },
  // poolOrderList
  {
    path: '/poolOrderList',
    name: 'poolOrderList',
    meta: {
      title: "球桌订单列表",
      key: 'pool-order-list',
      pageApisPrm: import("../views/marketManage/poolOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "../views/marketManage/poolOrderList.vue"
      ),
  },
  {
    path: '/poolBrandList',
    name: 'poolBrandList',
    meta: {
      title: "球桌型号",
      key: 'pool-brand-list',
      pageApisPrm: import("../views/marketManage/poolBrandList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "../views/marketManage/poolBrandList.vue"
      ),
  },
  {
    path: '/marketAccountList',
    name: 'marketAccountList',
    meta: {
      title: "账号管理",
      key: 'account-manage-list',
      pageApisPrm: import("../views/marketManage/accountList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "../views/marketManage/accountList.vue"
      ),
  },
]

// 卡券管理
export const couponManageRoutes = [
  // {
  //   path: '/couponTimeDuration',
  //   name: 'couponTimeDuration',
  //   meta: {
  //     title: "卡券时间段设置",
  //     key: 'coupon-time-duration',
  //     pageApisPrm: import("../views/couponManage/couponTimeDuration.vue").then(({ pageApiList }: any) => pageApiList)
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "couponManage" */ "../views/couponManage/couponTimeDuration.vue"
  //     ),
  // },
  // cashierCouponVertifyCodeRecord
  {
    path: "/cashierCouponVertifyCodeRecord",
    name: "cashierCouponVertifyCodeRecord",
    meta: {
      title: "收银台团购券核销记录",
      // asideHidden: true, // 侧边栏不显示
      key: 'cashier-coupon-vertify-code-record',
      pageApisPrm: import("../views/couponManage/cashierCouponVertifyCodeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "../views/couponManage/cashierCouponVertifyCodeRecord.vue"),
  },
  {
    path: "/couponVertifyCodeRecord",
    name: "couponVertifyCodeRecord",
    meta: {
      title: "团购券核销记录",
      // asideHidden: true, // 侧边栏不显示
      key: 'coupon-vertify-code-record',
      pageApisPrm: import("../views/couponManage/couponVertifyCodeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "../views/couponManage/couponVertifyCodeRecord.vue"),
  },
  {
    path: "/couponList",
    name: "couponList",
    meta: {
      title: "美团卡券",
      key: 'coupon-list',
      pageApisPrm: import("../views/couponManage/couponList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "../views/couponManage/couponList.vue"),
  },
  {
    path: "/couponList_dy",
    name: "couponList_dy",
    meta: {
      title: "抖音卡券",
      // asideHidden: true, // 侧边栏不显示
      key: 'dy-coupon-list',
      pageApisPrm: import("../views/couponManage/couponList_dy.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "../views/couponManage/couponList_dy.vue"),
  },
  // couponList_ks
  {
    path: "/couponList_ks",
    name: "couponList_ks",
    meta: {
      title: "快手卡券",
      // asideHidden: true, // 侧边栏不显示
      key: 'ks-coupon-list',
      pageApisPrm: import("../views/couponManage/couponList_ks.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "../views/couponManage/couponList_ks.vue"),
  },


]


// 系统管理
export const systemManageRoutes = [
  // cashierRightMenuList
  {
    path: "/cashierRightMenuList",
    name: "cashierRightMenuList",
    meta: {
      title: "收银台菜单管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'cashier-right-menu-list',
      pageApisPrm: import("../views/systemManage/cashierRightMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/cashierRightMenuList.vue"),
  },
  {
    path: "/departmentList",
    name: "departmentList",
    meta: {
      title: "部门管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'department-list',
      pageApisPrm: import("../views/systemManage/departmentList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/departmentList.vue"),
  },
  {
    path: "/rightMenuList",
    name: "rightMenuList",
    meta: {
      title: "菜单管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'right-menu-list',
      pageApisPrm: import("../views/systemManage/rightMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/rightMenuList.vue"),
  },
  // systemUserMember
  {
    path: "/systemUserMember",
    name: "systemUserMember",
    meta: {
      title: "角色管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'system-member-manage',
      pageApisPrm: import("../views/systemManage/systemUserMember.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/systemUserMember.vue"),
  },
  {
    path: "/systemOptionLog",
    name: "systemOptionLog",
    meta: {
      title: "操作日志",
      // asideHidden: true, // 侧边栏不显示
      key: 'system-option-log',
      pageApisPrm: import("../views/systemManage/systemOptionLog.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/systemOptionLog.vue"),
  },
  // pandaBallMerchantMenuList
  {
    path: "/pandaBallMerchantMenuList",
    name: "pandaBallMerchantMenuList",
    meta: {
      title: "熊猫球社商家端菜单配置管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'panda-ball-merchant-menu-list',
      pageApisPrm: import("../views/systemManage/pandaBallMerchantMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/pandaBallMerchantMenuList.vue"),
  },
  // pandaGloryMerchantMenuList
  {
    path: "/pandaGloryMerchantMenuList",
    name: "pandaGloryMerchantMenuList",
    meta: {
      title: "熊猫国粹馆商家端菜单配置管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'panda-glory-merchant-menu-list',
      pageApisPrm: import("../views/systemManage/pandaGloryMerchantMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/pandaGloryMerchantMenuList.vue"),
  },
]

/**
 *  之前项目的某一管理大类，
 * 当前项目暂时没有
 *
 */
function getMidLen() {
  const dbRoutesLen = dataWholeBoardRoutes.length
  console.log(dbRoutesLen, 'dbRoutesLen')

  const mMRoutesLen = merchantManageRoutes.length
  console.log(mMRoutesLen, 'mMRoutesLen')

  const uMRoutesLen = userManageRoutes.length
  console.log(uMRoutesLen, 'uMRoutesLen')

  const cpeteMRoutesLen = competitionManageRoutes.length
  console.log(cpeteMRoutesLen, 'cpeteMRoutesLen')

  const cSRoutesLen = customerServiceRoutes.length
  console.log(cSRoutesLen, 'cSRoutesLen')

  const oMRoutesLen = operationManageRoutes.length
  console.log(oMRoutesLen, 'oMRoutesLen')

  const fMRoutesLen = financeManageRoutes.length
  console.log(fMRoutesLen, 'fMRoutesLen')

  const aMRoutesLen = auditManageRoutes.length
  console.log(aMRoutesLen, 'aMRoutesLen')

  const oWMRoutesLen = officialWebManageRoutes.length
  console.log(oWMRoutesLen, 'oWMRoutesLen')

  const marMRoutesLen = marketManageRoutes.length
  console.log(marMRoutesLen, 'marMRoutesLen')

  const cMRoutesLen = couponManageRoutes.length
  console.log(cMRoutesLen, 'cMRoutesLen')

  const sOLRoutesLen = systemManageRoutes.length
  console.log(sOLRoutesLen, 'sOLRoutesLen')

  const totalLen = dbRoutesLen + mMRoutesLen + uMRoutesLen + cpeteMRoutesLen + cSRoutesLen + oMRoutesLen + fMRoutesLen + aMRoutesLen + oWMRoutesLen + marMRoutesLen + cMRoutesLen + sOLRoutesLen
  console.log(totalLen, 'totalLen')

  return totalLen / 2
}

console.log(getMidLen(), 'getMidLen')



const routes = [
  {
    path: "/",
    redirect: () => {
      const token = localStorage.getItem('token')

      if (token) {
        const menusStr = localStorage.getItem('menus')

        const menus: any = menusStr ? JSON.parse(menusStr) : []

        // console.log(menus, 'menus')

        const {
          children = []
        } = menus[0] || {}

        const firstChild = children[0] || {}
        return firstChild.url || "/login"
      } else {
        return "/login"
      }
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      ...dataWholeBoardRoutes,
      // ...dataStatisticRoutes,
      ...merchantManageRoutes,
      // ...accountManangeRoutes,
      ...officialWebManageRoutes,
      ...marketManageRoutes,

      ...userManageRoutes,
      ...competitionManageRoutes,
      ...customerServiceRoutes,
      ...operationManageRoutes,
      ...financeManageRoutes,
      ...auditManageRoutes,
      ...couponManageRoutes,
      ...systemManageRoutes
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
